import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ласкаво просимо до TileMaster Services
			</title>
			<meta name={"description"} content={"Експертна майстерність у кожній плитці"} />
			<meta property={"og:title"} content={"Ласкаво просимо до TileMaster Services"} />
			<meta property={"og:description"} content={"Експертна майстерність у кожній плитці"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="quarklycommunityKitMenuOverride7" />
			<Override slot="quarklycommunityKitMenuOverride5" />
			<Override slot="quarklycommunityKitMenuOverride6" />
			<Override slot="text" />
			<Override slot="icon" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
			<Override slot="icon1" />
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z) bottom/cover repeat scroll padding-box">
			<Override slot="text">
				Ласкаво просимо до TileMaster Services
			</Override>
			<Override slot="text1" width="90%">
				Ласкаво просимо до TileMaster Services, де ваше бачення ідеально покладеної плитки стає реальністю. Незалежно від того, ремонтуєте ви свій дім чи плануєте оновити комерційне приміщення, наш досвід у укладанні плитки гарантує бездоганну обробку кожного разу. Відкрийте для себе нашу відданість якості та деталям, що відрізняє нас.
			</Override>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text4" />
			<Override slot="text5" />
			<Override slot="text6" />
			<Override slot="box" />
			<Override slot="link" href="/services" />
		</Components.Hero>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Про нас
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--lead"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					У TileMaster Services ми спеціалізуємося на професійному укладанні плитки для різноманітних об’єктів, включаючи житлові, комерційні та промислові об’єкти. Завдяки багаторічному досвіду та гострому погляду на точність наша кваліфікована команда обіцяє виняткове обслуговування від початку до кінця. Ми пишаємося тим, що використовуємо лише найякісніші матеріали та новітні технології для досягнення видатних результатів.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--headline3"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Наш ретельний підхід гарантує ідеальне вирівнювання та надійне розміщення кожної плитки.
				</Text>
				<Link
					href="#"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Наші послуги
				</Link>
			</Box>
			<Box
				display="flex"
				lg-flex-wrap="wrap"
				width="40%"
				flex-direction="column"
				lg-width="50%"
				md-width="100%"
				justify-content="center"
			>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						1
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Гарантована довговічність
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							ми використовуємо матеріали найвищого рівня, які витримують випробування часом, гарантуючи, що ваші інвестиції триватимуть.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						2
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Індивідуальний дизайн
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							індивідуальні рішення, які відповідають вашим конкретним естетичним і функціональним потребам.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					flex-direction="row"
					margin="0px 0px 40px 0px"
					lg-margin="0px 4% 15px 0px"
					sm-width="100%"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 30px 0px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--darkL2"
						width="49px"
						height="49px"
						background="--color-lightD1"
						sm-margin="0px 14px 0px 0px"
					>
						3
					</Text>
					<Box>
						<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Ефективність і чистота
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							ми поважаємо ваш простір, завершуючи проекти вчасно з мінімальними збоями.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-primary" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="--headline2" color="--light">
				Наші Послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Житлове укладання плитки
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Перетворіть свій дім за допомогою наших спеціалізованих послуг з укладання плитки. Від ванних кімнат до кухонь і відкритих терас, ми створюємо естетичні та функціональні простори, які ви будете любити кожен день. Наші досвідчені майстри працюють з різними матеріалами, такими як кераміка, порцеляна та натуральний камінь, щоб відповідати вашому стилю та бюджету.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							Комерційне укладання плитки
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Надайте вашому бізнесу професійного вигляду з допомогою нашого комерційного укладання плитки. Ми обслуговуємо офіси, ресторани, магазини та інші комерційні приміщення, забезпечуючи довговічні та стильні рішення. Наші проекти виконуються з урахуванням ваших потреб у міцності та естетиці, щоб створити вражаючий інтер'єр, який приваблює клієнтів.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							Промислове укладання плитки
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Для промислових об'єктів ми пропонуємо надійні та міцні рішення з укладання плитки. Наші послуги підходять для складських приміщень, виробничих об'єктів та інших промислових середовищ, де потрібна висока стійкість до зносу. Ми гарантуємо, що наші промислові укладання витримають навіть найсуворіші умови експлуатації.
					</Text>
				</Box>
			</Box>
			<Button
				margin="2rem 0px 0px 0px"
				box-shadow="10px 10px 10px -5px rgba(249, 242, 242, 0.1)"
				type="link"
				text-transform="uppercase"
				text-align="center"
				text-decoration-line="initial"
				border-width="1px"
				border-style="solid"
				href="/services"
			>
				<Strong>
					Всі послуги
				</Strong>
			</Button>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});